var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showGisFieldsDialog,
        "max-width": "650px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0 align-center",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("GIS Fields")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { outlined: "" }, on: { click: _vm.syncGisFields } },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-sync-circle"),
                  ]),
                  _vm._v("Sync GIS Fields"),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit-gis-fields-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _c("p", [
                _vm._v(
                  "GIS fields are used to store information about a feature or site."
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "d-flex gap py-1",
                  class: {
                    "justify-end": _vm.someSelected,
                    "justify-space-between": !_vm.someSelected,
                  },
                },
                [
                  !_vm.someSelected
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "d-flex gap align-center",
                          on: { click: _vm.toggleVisible },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.showVisible ? "Visible" : "Hidden") +
                              " "
                          ),
                          _c(
                            "v-icon",
                            { staticClass: "ml-1", attrs: { size: "18" } },
                            [_vm._v(_vm._s(_vm.mdiSyncCircle))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.someSelected
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "px-0 mx-0 d-flex align-center",
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleFieldsVisibility()
                            },
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.showVisible ? _vm.mdiEyeOff : _vm.mdiEye
                              )
                            ),
                          ]),
                          _vm._v(
                            " Make Fields " +
                              _vm._s(_vm.showVisible ? "Hidden" : "Visible") +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm.showVisible
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "px-0 mx-0",
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addSectionLabel()
                            },
                          },
                        },
                        [_vm._v(" + Add Section Label ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-simple-table",
                        { attrs: { height: "40vh", "fixed-header": "" } },
                        [
                          _c("thead", [
                            _c("tr", [
                              _vm.showVisible ? _c("th") : _vm._e(),
                              _c("th", { staticClass: "pl-2 pr-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mx-0 mt-2 mb-n2",
                                      attrs: {
                                        value: _vm.allSelected,
                                        indeterminate: _vm.someSelected,
                                        color: "primary",
                                      },
                                      on: { change: _vm.selectAll },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("th", { staticClass: "pl-2 pr-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [_vm._v("UtiliSync Label")]
                                ),
                              ]),
                              _c("th", { staticClass: "pl-2 pr-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [_vm._v("ArcGIS Field")]
                                ),
                              ]),
                              _c("th", { staticClass: "pl-2 pr-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [_vm._v("ArcGIS Alias")]
                                ),
                              ]),
                              _c("th", { staticClass: "pl-2 pr-0" }),
                            ]),
                          ]),
                          _vm.notAuthenticatedToEsri
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "6" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center pa-5",
                                    },
                                    [
                                      _vm._v(
                                        " Sign in with ArcGIS to view fields. "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : [
                                _vm.mapServiceFields.length > 0
                                  ? _c(
                                      "draggable",
                                      {
                                        attrs: {
                                          list: _vm.mapServiceFields,
                                          tag: "tbody",
                                          disabled: !_vm.showVisible,
                                        },
                                        on: {
                                          end: function ($event) {
                                            return _vm.onDragEnd(
                                              _vm.mapServiceFields
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.getMapServiceFieldsByVisibility(
                                          _vm.mapServiceFields,
                                          _vm.showVisible
                                        ),
                                        function (field) {
                                          return _c(
                                            "tr",
                                            { key: field.id },
                                            [
                                              _vm.showVisible
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "white pl-1 pr-0 mx-0 cursor-grab",
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.mdiDragVertical
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "white pl-2 pr-0",
                                                },
                                                [
                                                  [
                                                    _vm.GIS_FIELD_TYPES.FIELD,
                                                    _vm.GIS_FIELD_TYPES.ID,
                                                  ].includes(
                                                    _vm.getFieldType(field)
                                                  )
                                                    ? _c("v-checkbox", {
                                                        staticClass:
                                                          "mx-0 mt-2 mb-n2",
                                                        model: {
                                                          value:
                                                            _vm
                                                              .visibilityCheckBoxesSelected[
                                                              field.id
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.visibilityCheckBoxesSelected,
                                                              field.id,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "visibilityCheckBoxesSelected[field.id]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm.getFieldType(field) ===
                                              _vm.GIS_FIELD_TYPES.FIELD
                                                ? [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "white pl-2 pr-0",
                                                      },
                                                      [
                                                        !_vm
                                                          .editingUtiliSyncLabel[
                                                          field.id
                                                        ]
                                                          ? _c(
                                                              "section",
                                                              {
                                                                staticClass:
                                                                  "white cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.editingUtiliSyncLabel,
                                                                        field.id,
                                                                        true
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getGisField(
                                                                        field.fieldId
                                                                      ) &&
                                                                        _vm
                                                                          .getGisField(
                                                                            field.fieldId
                                                                          )
                                                                          .labels.join(
                                                                            ", "
                                                                          )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "section",
                                                              {
                                                                staticClass:
                                                                  "white",
                                                              },
                                                              [
                                                                _c(
                                                                  "validation-provider",
                                                                  {
                                                                    attrs: {
                                                                      name: "UtiliSync Label",
                                                                      rules:
                                                                        "required",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              errors,
                                                                              valid,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "UtiliSync Label",
                                                                                        "hide-details":
                                                                                          "",
                                                                                        "error-messages":
                                                                                          errors,
                                                                                        success:
                                                                                          valid,
                                                                                        name: "utiliSyncLabel",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          field
                                                                                            .labels[0],
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              field.labels,
                                                                                              0,
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "field.labels[0]",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "my-2 d-flex align-center justify-end gap",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-1 px-0",
                                                                                        attrs:
                                                                                          {
                                                                                            text: "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.$set(
                                                                                                _vm.editingUtiliSyncLabel,
                                                                                                field.id,
                                                                                                false
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Cancel "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.editingUtiliSyncLabel,
                                                                                                field.id,
                                                                                                false
                                                                                              )
                                                                                              _vm.onGisLayerSaved()
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Save "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "white pl-2 pr-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getGisField(
                                                                field.fieldId
                                                              ) &&
                                                                _vm.getGisField(
                                                                  field.fieldId
                                                                ).name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "white pl-2 pr-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getGisField(
                                                                field.fieldId
                                                              ) &&
                                                                _vm.getGisField(
                                                                  field.fieldId
                                                                ).alias
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm.getFieldType(field) ===
                                                  _vm.GIS_FIELD_TYPES
                                                    .SECTION_LABEL
                                                ? [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "white px-2",
                                                      },
                                                      [
                                                        !_vm
                                                          .editingSectionLabel[
                                                          field.id
                                                        ]
                                                          ? _c(
                                                              "section",
                                                              {
                                                                staticClass:
                                                                  "white d-flex align-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editSectionLabel(
                                                                        field
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      field.sectionLabel
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "section",
                                                              {
                                                                staticClass:
                                                                  "white",
                                                              },
                                                              [
                                                                _c(
                                                                  "validation-provider",
                                                                  {
                                                                    attrs: {
                                                                      name: "Section Label",
                                                                      rules:
                                                                        "required",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              errors,
                                                                              valid,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Section Label",
                                                                                        "hide-details":
                                                                                          "",
                                                                                        "error-messages":
                                                                                          errors,
                                                                                        success:
                                                                                          valid,
                                                                                        name: "sectionLabel",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          field.sectionLabel,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              field,
                                                                                              "sectionLabel",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "field.sectionLabel",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "my-2 d-flex align-center justify-end gap",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-1 px-0",
                                                                                        attrs:
                                                                                          {
                                                                                            text: "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.cancelEditSectionLabel(
                                                                                                field
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Cancel "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.editingSectionLabel,
                                                                                                field.id,
                                                                                                false
                                                                                              )
                                                                                              _vm.onGisLayerSaved()
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Save "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    ),
                                                    _c("td", {
                                                      staticClass: "white",
                                                      attrs: { colspan: "2" },
                                                    }),
                                                  ]
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "white pl-2 pr-0",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end align-center px-2",
                                                    },
                                                    [
                                                      _vm.getFieldType(
                                                        field
                                                      ) ===
                                                      _vm.GIS_FIELD_TYPES.FIELD
                                                        ? _c(
                                                            "v-menu",
                                                            {
                                                              staticClass:
                                                                "white",
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                    },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.mdiDotsVertical
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "v-list",
                                                                [
                                                                  field.fieldId
                                                                    ? _c(
                                                                        "v-list-item",
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.editingUtiliSyncLabel,
                                                                                  field.id,
                                                                                  true
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            {
                                                                              staticClass:
                                                                                "primary--text text--lighten-1",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.mdiPencil
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " Edit UtiliSync Label "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            field.visible =
                                                                              !field.visible
                                                                            _vm.onGisLayerSaved()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text text--lighten-1",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    field.visible
                                                                                      ? _vm.mdiEyeOff
                                                                                      : _vm.mdiEye
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.visible
                                                                                  ? "Hide"
                                                                                  : "Show"
                                                                              ) +
                                                                              " GIS Field "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm.getFieldType(
                                                            field
                                                          ) ===
                                                          _vm.GIS_FIELD_TYPES
                                                            .SECTION_LABEL
                                                        ? _c(
                                                            "v-menu",
                                                            {
                                                              staticClass:
                                                                "white",
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                    },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.mdiDotsVertical
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "v-list",
                                                                [
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.editSectionLabel(
                                                                              field
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text text--lighten-1",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.mdiPencil
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " Edit Section Label "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteSectionLabel(
                                                                              field.id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text text--lighten-1",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.mdiDelete
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " Delete Section Label "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end pa-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary" },
                  on: { click: _vm.returnToUtilibot },
                },
                [_vm._v(" Return to Utilibot ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }